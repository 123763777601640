/* src/styles/Resume.css */
html, body {
  margin: 0;
  padding: 0;
  overflow: auto; 
  width: 100%;
  /* height: 100%;  /* Ensure the body takes the full height of the viewport */
}

.resume-container {
  width: auto;   /* Full width of the viewport */
  height: 100vh;  /* Full height of the viewport */
  margin: 80px;
  padding: 0;
}

.resume-embed {
  width: 100%;   /* Full width of the viewport */
  height: 100%;  /* Full height of the viewport */
  border: auto;   /* Remove any border */
}

.resume-content {
  z-index: 1; /* Ensure the content is above the background and overlay */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  color: white; /* Change text color to white to stand out on dark background */
}

.resume-content h1 {
  font-size: 4rem; /* Adjust font size as needed */
  color: rgb(255, 255, 255);
}