@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* Ensure full width of html and body */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box;
  background-color: #fffefe;
  }

.project-section {
  display: block;
  width: 100vw;
  padding: 0;
  margin: 0;
  text-align: center;
  overflow: hidden;
  background-color: transparent;
}

.project-content {
  z-index: 1; /* Ensure the content is above the background and overlay */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  color: white; /* Change text color to white to stand out on dark background */
}

.project-content h1 {
  font-size: 4rem; /* Adjust font size as needed */
  color: rgb(255, 255, 255);
}

/* ! >> Variables and Mixins
==============  =========================================== */
.anima1 {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.anima-none {
  -webkit-transition: none!important;
  -moz-transition: none!important;
  transition: none!important;
}
.anima-transform {
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
}
.background-cover,
.bg-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.elpt-portfolio {
  padding-top: 20px;
  padding-bottom: 20px;
}
.elpt-portfolio-filter {
  text-align: center;
  padding-bottom: 20px;
}
.elpt-portfolio-filter .portfolio-filter-item {
  border: 0;
  background: #333;
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  margin-right: 3px;
  margin-bottom: 3px;
  font-size: 13px;
  outline: none!important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.elpt-portfolio-filter .portfolio-filter-item:hover {
  background: #b11818;
  opacity: 0.9;
}
.elpt-portfolio-filter .portfolio-filter-item.item-active {
  background: #555;
  opacity: 0.8;
  outline: none!important;
}
.elpt-portfolio-content:after {
  content: '';
  clear: both;
}

.elpt-portfolio-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centers the items horizontally */
  gap: 20px; /* Adds space between the items */
}

.elpt-portfolio-content .portfolio-item-wrapper {
  width: calc(33.33% - 20px); /* Adjust the width to account for the gap */
  margin-bottom: 20px; /* Space between rows */
}

@media (min-width: 768px) {
  .elpt-portfolio-content .portfolio-item-wrapper {
    width: calc(50% - 20px); /* Two items per row for medium screens */
  }
}

@media (min-width: 992px) {
  .elpt-portfolio-content .portfolio-item-wrapper {
    width: calc(33.33% - 20px); /* Three items per row for large screens */
  }
}


.elpt-portfolio-content .portfolio-item-wrapper img {
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #f4f4f4;
}
.elpt-portfolio-content .portfolio-item {
  display: block;
  position: relative;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}
.elpt-portfolio-content p {
  display: none;
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-columns-3 .portfolio-item-wrapper {
    width: 50%;
  }
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-columns-3 .portfolio-item-wrapper {
    width: 33.33%;
  }
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-columns-4 .portfolio-item-wrapper {
    width: 25%;
  }
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-columns-5 .portfolio-item-wrapper {
    width: 20%;
  }
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-columns-6 .portfolio-item-wrapper {
    width: 16.66%;
  }
}
@media (max-width: 992px) {
  .elpt-portfolio-content.elpt-portfolio-columns-mobile-2 .portfolio-item-wrapper {
    width: 50%;
  }
}
@media (max-width: 992px) {
  .elpt-portfolio-content.elpt-portfolio-columns-mobile-3 .portfolio-item-wrapper {
    width: 33.33%;
  }
}
.elpt-portfolio-content.elpt-portfolio-margin .portfolio-item-wrapper {
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}
.elpt-portfolio-content.elpt-portfolio-style-box .portfolio-item {
  height: 250px;
}
@media (min-width: 1400px) {
  .elpt-portfolio-content.elpt-portfolio-style-box .portfolio-item {
    height: 400px;
  }
}
.elpt-portfolio-content.elpt-portfolio-style-box .portfolio-item img {
  display: none;
}
.elpt-portfolio-content .portfolio-item-infos-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  z-index: 9;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0;
}
.elpt-portfolio-content .portfolio-item-infos {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  padding: 20px;
  margin-top: -40px;
  z-index: 10;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.elpt-portfolio-content .portfolio-item-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 2px;
}
.elpt-portfolio-content .portfolio-item-desc {
  font-size: 12px;
}
.elpt-portfolio-content .portfolio-item-category {
  font-size: 11px;
  font-style: italic;
  display: inline-block;
  opacity: 0.7;
  line-height: 1;
}
.elpt-portfolio-content .elpt-portfolio-cat {
  display: inline-block;
  padding: 3px;
}
.elpt-portfolio .isotope-pager {
  text-align: center;
}
.elpt-portfolio .isotope-pager a {
  display: inline-block;
  padding: 6px;
  margin-right: 3px;
  background: #333;
  color: #fff;
  font-size: 12px;
  border-radius: 3px;
  line-height: 1;
}
.elpt-portfolio .isotope-pager a:focus,
.elpt-portfolio .isotope-pager a:hover,
.elpt-portfolio .isotope-pager a.active {
  background: #555;
  opacity: 0.8;
}
.elpt-portfolio-style-box.zoom_effect img {
  display: block !important;
  min-height: 100%;
  max-width: auto;
  width: auto;
  object-fit: cover;
}
.elpt-portfolio-content.zoom_effect .portfolio-item {
  overflow: hidden;
}
.elpt-portfolio-content.zoom_effect .portfolio-item img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.elpt-portfolio-content.zoom_effect .portfolio-item:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.elpt-portfolio-content.simple .portfolio-item {
  display: block;
  position: relative;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.elpt-portfolio-content.simple .portfolio-item:hover .portfolio-item-infos-wrapper {
  opacity: 0.95;
}
.elpt-portfolio-content.simple .portfolio-item:hover .portfolio-item-infos {
  opacity: 1!important;
  transform: translateY(0px) !important;
  -webkit-transform: translateY(0px) !important;
  -moz-transform: translateY(0px) !important;
}
.elpt-portfolio-content.simple .portfolio-item-infos-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  z-index: 9;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0;
}
.elpt-portfolio-content.simple .portfolio-item-infos {
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.elpt-portfolio-content.simple p {
  display: none;
}
.elpt-portfolio-content.hover1 .portfolio-item {
  display: block;
  position: relative;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}
.elpt-portfolio-content.hover1 .portfolio-item:hover .portfolio-item-infos-wrapper {
  opacity: 0.95;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
}
.elpt-portfolio-content.hover1 .portfolio-item:hover .portfolio-item-infos {
  opacity: 1!important;
  transform: translateY(0px) !important;
  -webkit-transform: translateY(0px) !important;
  -moz-transform: translateY(0px) !important;
}
.elpt-portfolio-content.hover1 .portfolio-item-infos-wrapper {
  position: absolute;
  transform: translateY(40%);
  -webkit-transform: translateY(40%);
  -moz-transform: translateY(40%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  z-index: 9;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0;
}
.elpt-portfolio-content.hover1 .portfolio-item-infos {
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.elpt-portfolio-content.hover1 p {
  display: none;
}

/* Add this to your CSS file (Projects.css) */
.portfolio-item img {
  width: 100%; /* Ensures the image takes full width of its container */
  height: 500px; /* Set a fixed height for consistency */
  object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
  display: block; /* Removes any gaps caused by inline elements */
}


.element-hsize-1 {
  height: 279px;
}
.element-hsize-1 .portfolio-item {
  height: 279px;
}
.element-hsize-2 {
  height: 558px;
}
.element-hsize-2 .portfolio-item {
  height: 558px;
}
.element-size-1 {
  width: 100%;
  height: 279px;
}
.element-size-1 .portfolio-item {
  height: 279px;
}
@media (min-width: 768px) {
  .element-size-1 {
    width: 50%;
  }
}
@media (min-width:992px) {
  .element-size-1 {
    width: 25%;
  }
}
.element-size-2 {
  width: 100%;
}
@media (min-width:992px) {
  .element-size-2 {
    width: 50%;
  }
}
.element-size-3 {
  width: 100%;
}
@media (min-width:992px) {
  .element-size-3 {
    width: 75%;
  }
}
.element-size-4 {
  width: 100%;
}
.element-size-4 .portfolio-item {
  height: 279px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper {
  box-sizing: border-box;
  padding: 0px !important;
  width: 100%;
  height: 279px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper .portfolio-item {
  height: 279px;
}
@media (min-width: 768px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper {
    width: 50%;
  }
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper {
    width: 25%;
  }
}
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper * {
  box-sizing: border-box;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper .portfolio-item {
  height: 279px;
}
@media (min-width: 768px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper {
    width: 50%;
  }
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper {
    width: 25%;
  }
}
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper img {
  display: none;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(3),
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(6),
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(9),
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(12) {
  width: 100%;
  height: 558px;
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(3),
  .elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(6),
  .elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(9),
  .elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(12) {
    width: 50%;
  }
}
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(3) .portfolio-item,
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(6) .portfolio-item,
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(9) .portfolio-item,
.elpt-portfolio-content.elpt-portfolio-special-grid-1 .portfolio-item-wrapper:nth-child(12) .portfolio-item {
  height: 558px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-2 .portfolio-item-wrapper {
  box-sizing: border-box;
  padding: 0px !important;
  width: 100%;
  height: 279px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-2 .portfolio-item-wrapper * {
  box-sizing: border-box;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-2 .portfolio-item-wrapper .portfolio-item {
  height: 279px;
}
@media (min-width: 768px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-2 .portfolio-item-wrapper {
    width: 50%;
  }
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-2 .portfolio-item-wrapper {
    width: 25%;
  }
}
.elpt-portfolio-content.elpt-portfolio-special-grid-2 .portfolio-item-wrapper img {
  display: none;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-2 .portfolio-item-wrapper:nth-child(3n+1) {
  height: 558px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-2 .portfolio-item-wrapper:nth-child(3n+1) .portfolio-item {
  height: 558px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper {
  padding: 0px !important;
  width: 100%;
  height: 279px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper * {
  box-sizing: border-box;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper .portfolio-item {
  height: 279px;
}
@media (min-width: 768px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper {
    width: 50%;
  }
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper {
    width: 25%;
  }
}
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper img {
  display: none;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(1),
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(7),
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(9) {
  height: 558px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(1) .portfolio-item,
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(7) .portfolio-item,
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(9) .portfolio-item {
  height: 558px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(3),
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(5),
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(11) {
  width: 100%;
  height: 558px;
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(3),
  .elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(5),
  .elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(11) {
    width: 50%;
  }
}
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(3) .portfolio-item,
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(5) .portfolio-item,
.elpt-portfolio-content.elpt-portfolio-special-grid-3 .portfolio-item-wrapper:nth-child(11) .portfolio-item {
  height: 558px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-4 .portfolio-item-wrapper {
  box-sizing: border-box;
  padding: 0px !important;
  width: 100%;
  height: 279px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-4 .portfolio-item-wrapper * {
  box-sizing: border-box;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-4 .portfolio-item-wrapper .portfolio-item {
  height: 279px;
}
@media (min-width: 768px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-4 .portfolio-item-wrapper {
    width: 50%;
  }
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-4 .portfolio-item-wrapper {
    width: 25%;
  }
}
.elpt-portfolio-content.elpt-portfolio-special-grid-4 .portfolio-item-wrapper img {
  display: none;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-4 .portfolio-item-wrapper:nth-child(2n) {
  width: 100%;
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-4 .portfolio-item-wrapper:nth-child(2n) {
    width: 50%;
  }
}
.elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper {
  padding: 5px !important;
  margin-bottom: 5px;
  width: 100%;
  height: 279px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper * {
  box-sizing: border-box;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper .portfolio-item {
  height: 279px;
}
@media (min-width: 768px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper {
    width: 50%;
  }
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper {
    width: 25%;
  }
}
.elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper img {
  display: none;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper:nth-child(3),
.elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper:nth-child(4),
.elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper:nth-child(9),
.elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper:nth-child(10) {
  width: 100%;
}
@media (min-width:992px) {
  .elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper:nth-child(3),
  .elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper:nth-child(4),
  .elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper:nth-child(9),
  .elpt-portfolio-content.elpt-portfolio-special-grid-5 .portfolio-item-wrapper:nth-child(10) {
    width: 50%;
  }
}
.elpt-portfolio-content.elpt-portfolio-special-grid-6 .portfolio-item-wrapper {
  padding: 5px !important;
  margin-bottom: 5px;
  width: 33.33%;
  height: 279px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-6 .portfolio-item-wrapper * {
  box-sizing: border-box;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-6 .portfolio-item-wrapper .portfolio-item {
  height: 279px;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-6 .portfolio-item-wrapper img {
  display: none;
}
.elpt-portfolio-content.elpt-portfolio-special-grid-6 .portfolio-item-wrapper:nth-child(4),
.elpt-portfolio-content.elpt-portfolio-special-grid-6 .portfolio-item-wrapper:nth-child(9),
.elpt-portfolio-content.elpt-portfolio-special-grid-6 .portfolio-item-wrapper:nth-child(11) {
  width: 66.66%;
}
.elpt-portfolio-content.elpt-portfolio-grid-builder.elpt-portfolio-margin {
  padding: 5px !important;
  margin-bottom: 5px;
}
.elpt-portfolio-content.elpt-portfolio-grid-builder .portfolio-item-wrapper {
  width: 25%;
}
.elpt-portfolio-content.elpt-portfolio-grid-builder .portfolio-item-wrapper * {
  box-sizing: border-box;
}
.elpt-portfolio-content.elpt-portfolio-grid-builder .portfolio-item-wrapper img {
  display: none;
}
.elpt-portfolio-content.elpt-portfolio-carousel .owl-dots button,
.elpt-portfolio-content.elpt-portfolio-carousel .owl-nav button {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  line-height: normal;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.elpt-portfolio-content.elpt-portfolio-carousel .portfolio-item-wrapper {
  float: none;
  width: 100%;
  display: block;
  height: 250px;
}
@media (min-width: 1400px) {
  .elpt-portfolio-content.elpt-portfolio-carousel .portfolio-item-wrapper {
    height: 400px;
  }
}
.elpt-portfolio-content.elpt-portfolio-carousel .portfolio-item {
  height: 250px;
}
@media (min-width: 1400px) {
  .elpt-portfolio-content.elpt-portfolio-carousel .portfolio-item {
    height: 400px;
  }
}
.elpt-portfolio-content.elpt-portfolio-carousel .portfolio-item img {
  display: none;
}
