@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');



.project-details {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.project-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns for text and media */
  gap: 20px;
  margin-bottom: 40px;
}

.project-text {
  display: flex;
  flex-direction: column;
  color: #333;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
}

.project-text h2, .project-text h3 {
  margin: 1px;
}

.project-text h3, .project-text p {
  margin-top: 10px;
}

.project-text p {
  margin: 1px;
  font-size: 17px;
}

.project-media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-image, .project-full-width-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Reverse layout for the second section */
.project-details-grid.reverse {
  grid-template-columns: 1fr 1fr; /* Two columns */
  direction: ltr; /* Reverse the direction of content */
}

.project-details-grid.reverse .project-text {
  direction: ltr; /* Ensure text is left to right */
}

.project-full-width-image {
  margin-top: 40px;
}

/* Centered iframe container */
.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  background-color: #f5f5f5; /* Optional background color */
}

iframe {
  width: 80%; /* Make the iframe responsive */
  height: 400px; /* Adjust height as needed */
  border: none; /* Remove border for clean look */
}

/* Back button container at the bottom */
.back-button-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.back-button {
  border: 0;
  background: #333;
  color: #fff;
  padding: 8px 12px;
  margin-right: 3px;
  margin-bottom: 3px;
  font-size: 18px;
  outline: none!important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.back-button:hover {
  background: #b11818;
  color: black;
  opacity: 0.9;
}


/*------------------------------------skill------------------*/

.skill-section {
  padding-top: 20px;
  padding-bottom: 20px;
}
.skill-section-filter {
  text-align: center;
  padding-bottom: 20px;
}

.skill-section h3 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.skill-section p {
  font-size: 20px;
  margin-bottom: 1px;
}

.skill-section-filter .skill-filter-item {
  border: 0;
  background: #333;
  color: #fff;
  padding: 8px 12px;
  margin-right: 3px;
  margin-bottom: 3px;
  font-size: 18px;
  outline: none!important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.skill-section-filter .skill-filter-item:hover {
  background: #b11818;
  opacity: 0.9;
}

/*------------------------------- Responsive behavior -------------------------*/
@media (max-width: 768px) {
  .project-details-content {
    grid-template-columns: 1fr; /* Switch to 1 column on smaller screens */
  }
  
  .project-image, .project-diagram {
    margin-bottom: 30px; /* More space on mobile */
  }
}
